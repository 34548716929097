import { trackAction } from "utils/mixpanel"
import { YEARS_LABELS } from "constants/candidates"
import { conjureWatchedValues } from "utils/candidateProfile"
import AccordionFilter from "components/shared/Filters/AccordionFilter"
import HeaderFilter from "components/candidates/TagsFilters/Shared/HeaderFilter"
import SearchFilter from "components/candidates/TagsFilters/Shared/SearchFilter"
import YearsClosingExperienceFilter from "components/candidates/TagsFilters/Filters/YearsClosingExperience"
import TargetCompaniesFilter from "components/candidates/TagsFilters/Filters/TargetCompanies"
import styles from "./moreFilters.module.scss"

const MoreFilter = ({
  companies,
  setFilters,
  filters,
  handleSearchCompanies,
  resetFilter,
}) => {
  const searchCandidates = search => {
    setFilters({
      ...filters,
      search,
    })
    trackAction("Candidate Search Applied", { search_context: search })
  }

  return (
    <section className={styles.moreFilters}>
      <HeaderFilter
        label="More Filters"
        action={() =>
          resetFilter(["search", "selectedCompanies", "yearsClosingExperience"])
        }
      />

      <SearchFilter
        search={filters?.search}
        handleSearch={searchCandidates}
        placeholder="Search Candidates"
      />

      <article>
        <AccordionFilter
          title="Years Closing Experience"
          eventKey={1}
          body={
            <YearsClosingExperienceFilter
              setFilters={setFilters}
              filters={filters}
            />
          }
          className={styles.moreFilters__accordion}
          headerClassName={styles.moreFilters__accordion__header}
          icon="down-arrow"
          showBadge
          watchedValues={conjureWatchedValues(
            filters.yearsClosingExperience,
            0,
            11,
            "text",
            YEARS_LABELS
          )}
          showTags={false}
        />

        <AccordionFilter
          title="Target Companies"
          eventKey={2}
          body={
            <TargetCompaniesFilter
              setFilters={setFilters}
              filters={filters}
              options={companies}
              handleSearch={handleSearchCompanies}
            />
          }
          className={styles.moreFilters__accordion}
          headerClassName={styles.moreFilters__accordion__header}
          icon="down-arrow"
          showBadge
          showTags={false}
          watchedValues={
            filters.selectedCompanies?.length > 0
              ? filters.selectedCompanies.map(company =>
                  company.name ? company.name : company
                )
              : []
          }
        />
      </article>
    </section>
  )
}

export default MoreFilter
