import { useSelector } from "react-redux"
import { trackAction } from "utils/mixpanel"
import useHandleStatusUpdate from "components/jobs/hooks/useStatusUpdate"
import Icon from "components/Icon"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import Button from "components/shared/Buttons/Primary"
import { Link } from "react-router-dom"

import styles from "./headerActions.module.scss"

const HeaderActions = ({ jobInfo, jobsRemaining, hideView, location }) => {
  const { currentEmployer } = useSelector(state => state.auth)
  const { slug, id, status } = jobInfo || {}
  const { handleStatusUpdate } = useHandleStatusUpdate()
  const CLOSED = "closed"

  const buttonText = jobInfo ? "Edit Job" : "Post a Job"
  const buttonLink = jobInfo ? `/jobs/${id}/edit` : "/jobs/new"
  const buttonAction = status === CLOSED ? "Activate Job" : "Close Job"

  return (
    <article className={styles.headerActions}>
      {!currentEmployer?.company?.integration && !hideView && (
        <>
          {/* <div className={styles.headerActions__integration}>
            <SecondaryLightButton>
              <span>Integrate Your ATS</span>
            </SecondaryLightButton>
          </div> */}
          <div>
            <a
              href={
                slug
                  ? `${process.env.REACT_APP_USER_URL}/sales-jobs/${slug}`
                  : `${currentEmployer?.company?.public_url}/jobs`
              }
              target="_blank"
              rel="noreferrer"
            >
              <SecondaryLightButton
                className={styles.headerActions__external}
                onClick={() =>
                  trackAction("Call to Action Clicked", {
                    location: `${location} - Header`,
                    click_text: "View on RepVue",
                    click_url: slug
                      ? `${process.env.REACT_APP_USER_URL}/sales-jobs/${slug}`
                      : `${currentEmployer?.company?.public_url}/jobs`,
                    modal_name: null,
                  })
                }
              >
                <span>View on RepVue</span>
                <Icon name="external-link" height="22" width="22" />
              </SecondaryLightButton>
            </a>
          </div>
        </>
      )}
      {id && !currentEmployer?.company?.integration && (
        <div className={styles.headerActions__integration}>
          <SecondaryLightButton onClick={() => handleStatusUpdate(id, status)}>
            <span>{buttonAction}</span>
          </SecondaryLightButton>
        </div>
      )}

      {/* {currentEmployer?.company?.integration && (
        <SecondaryLightButton className={styles.headerActions__external}>
          <span>{currentEmployer?.company?.integration}</span>
          <Icon name="external-link" height="22" width="22" />
        </SecondaryLightButton>
      )} */}

      {jobInfo?.status !== CLOSED && (
        <Button
          className={styles.post}
          disabled={jobsRemaining === 0 && !jobInfo}
          onClick={() =>
            trackAction("Call to Action Clicked", {
              location: `${location} - Header`,
              click_text: buttonText,
              click_url: `${
                jobInfo ? "" : process.env.REACT_APP_USER_URL
              }${buttonLink}`,
              modal_name: null,
            })
          }
        >
          <Link to={buttonLink}>
            <Icon name="add" height="18" width="18" />
            {buttonText}
          </Link>
        </Button>
      )}
    </article>
  )
}

export default HeaderActions
