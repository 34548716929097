import styles from "./searchCell.module.scss"

const SearchCell = ({ name, count, delta }) => {
  return (
    <article className={styles.searchCell}>
      {delta > 0 && (
        <span className={styles.searchCell__tags__delta}>{delta} New</span>
      )}
      <span className={styles.searchCell__tags__tag}>{count}</span>
      <span className={styles.searchCell__name}>{name}</span>
    </article>
  )
}

export default SearchCell
