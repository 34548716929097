import Icon from "components/Icon"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import styles from "./saveSearch.candidate.module.scss"

const ClearAll = ({ onClick }) => {
  return (
    <article className={styles.clearAll}>
      <SecondaryLightButton onClick={onClick}>
        <Icon name="trash" height="16" width="16" />
        Clear All
      </SecondaryLightButton>
    </article>
  )
}

export default ClearAll
