import HeaderFilter from "components/candidates/TagsFilters/Shared/HeaderFilter"
import SliderFilter from "components/candidates/TagsFilters/Shared/SliderFilter"

const AvgDealSizeFilter = ({ setFilters, filters, resetFilter }) => {
  return (
    <section>
      <HeaderFilter
        label="Avg. Deal Size"
        action={() => resetFilter(["avgDealSize"])}
      />

      <SliderFilter
        min={0}
        max={1000000}
        filters={filters}
        filter="avgDealSize"
        setFilter={setFilters}
        step={2500}
        labelMin="$0"
        labelMax="$1M"
        type="currency"
      />
    </section>
  )
}

export default AvgDealSizeFilter
