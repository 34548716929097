import cn from "classnames"
import Imgix from "react-imgix"
import dayjs from "dayjs"
import utcPlugin from "dayjs/plugin/utc"
import styles from "./header.cardExperience.module.scss"
import Icon from "components/Icon"

const HeaderCardExperience = ({ current, experience }) => {
  dayjs.extend(utcPlugin)

  const formatDate = dateString => {
    return dayjs.utc(dateString).format("MMM YYYY")
  }

  return (
    <div className={styles.headerCardExperience}>
      <figure
        className={cn(styles.headerCardExperience__logo, {
          [styles.headerCardExperience__logo__current]: current,
        })}
      >
        {experience?.company?.logo ? (
          <Imgix
            width={50}
            height={50}
            src={`${process.env.REACT_APP_IMAGE_URL}/${experience?.company?.logo}`}
            alt={experience?.company?.name}
          />
        ) : (
          <Icon
            name="building"
            height={current ? "50" : "34"}
            width={current ? "50" : "34"}
          />
        )}
      </figure>

      <div className={styles.headerCardExperience__title}>
        <h5
          className={cn(styles.headerCardExperience__title__role, {
            [styles.headerCardExperience__title__role__current]: current,
          })}
        >
          {experience?.job_title}
        </h5>
        <div className={styles.headerCardExperience__title__tagContainer}>
          <span
            className={cn(styles.headerCardExperience__title__tag, {
              [styles.headerCardExperience__title__tag__current]: current,
            })}
          >
            {experience?.company_name}
          </span>
          <span
            className={cn(styles.headerCardExperience__title__tag, {
              [styles.headerCardExperience__title__tag__current]: current,
            })}
          >
            {formatDate(experience?.start_date)} -{" "}
            {!experience?.end_date
              ? "Present"
              : formatDate(experience?.end_date)}
          </span>
          {experience?.tenure_length && (
            <>
              <article>&#x2022;</article>
              <span
                className={cn(styles.headerCardExperience__title__tenure, {
                  [styles.headerCardExperience__title__tag__current]: current,
                })}
              >
                {experience?.tenure_length}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeaderCardExperience
