import { YEARS_LABELS } from "constants/candidates"
import SliderFilter from "components/candidates/TagsFilters/Shared/SliderFilter"

const YearsClosingExperienceFilter = ({ setFilters, filters }) => {
  return (
    <section className="mb-3">
      <SliderFilter
        min={0}
        max={11}
        filters={filters}
        filter="yearsClosingExperience"
        setFilter={setFilters}
        step={1}
        labelMin="<1Y"
        labelMax="10Y+"
        options={YEARS_LABELS}
        inputTye="text"
      />
    </section>
  )
}

export default YearsClosingExperienceFilter
