import Imgix from "react-imgix"
import Icon from "components/Icon"
import styles from "./logosList.sidebar.candidates.module.scss"

const LogosList = ({ companies = [] }) => {
  const uniqueCompanies = companies.filter(
    (item, index, array) =>
      array.findIndex(company => company.logo === item.logo) === index
  )

  return (
    <article className={styles.logosList}>
      {uniqueCompanies?.map((item, index) => (
        <figure
          key={`${item.id}-${index}`}
          style={{ zIndex: companies?.length - 1 - index }}
        >
          {item.logo ? (
            <Imgix
              src={`${process.env.REACT_APP_IMAGE_URL}/${item.logo}`}
              alt={item.name}
              width={36}
              height={36}
            />
          ) : (
            <Icon name="building" height="36" width="36" />
          )}
        </figure>
      ))}

      {companies?.length === 0 && (
        <figure>
          <Icon name="building" height="36" width="36" />
        </figure>
      )}
    </article>
  )
}

export default LogosList
