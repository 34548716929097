import styles from "./headerFilter.module.scss"

const HeaderFilter = ({ label, action }) => {
  return (
    <article className={styles.headerFilter}>
      <span className={styles.headerFilter__label}>{label}</span>
      <span onClick={action} className={styles.headerFilter__action}>
        Clear
      </span>
    </article>
  )
}

export default HeaderFilter
