import cn from "classnames"
import { useNavigate } from "react-router-dom"
import { toCamelCase } from "utils/common"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import Icon from "components/Icon"
import styles from "./itemSearch.savedSearches.candidate.module.scss"
dayjs.extend(relativeTime)

const ItemSearch = ({ setFilters, search, searchSelect, setSearchSelect }) => {
  const navigate = useNavigate()
  const FILTER_INFO = toCamelCase(search?.filter?.configuration || {})

  const updateUrl = uuid => {
    navigate(`/source-candidates/searches/${uuid}`)
  }

  const handleFilter = () => {
    setFilters({ ...FILTER_INFO })
    updateUrl(search?.uuid)
    setSearchSelect(search)
  }

  return (
    <article
      className={cn(styles.itemSearch, {
        [styles.itemSearch__selected]: search?.uuid === searchSelect?.uuid,
      })}
      onClick={handleFilter}
    >
      <div className={styles.itemSearch__container}>
        <div className={styles.itemSearch__information}>
          <h6 className={styles.itemSearch__information__title}>
            {search?.name}
          </h6>
          <p className={styles.itemSearch__information__description}>
            {search?.filter_label}
          </p>
        </div>

        <div className={styles.itemSearch__tags}>
          {search?.results_delta > 0 && (
            <span className={styles.itemSearch__tags__delta}>
              {search?.results_delta} New
            </span>
          )}

          <span className={styles.itemSearch__tags__tag}>
            {search?.results_count}
          </span>
        </div>
      </div>

      <div className={styles.itemSearch__time}>
        <Icon name="clock" height="14" width="14" />
        <span>{dayjs(search?.updated_at).fromNow()}</span>
      </div>
    </article>
  )
}

export default ItemSearch
