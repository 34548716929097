import { useState } from "react"
import { trackAction } from "utils/mixpanel"
import { Link } from "react-router-dom"
import useHandleStatusUpdate from "components/jobs/hooks/useStatusUpdate"
import Icon from "components/Icon"
import Card from "components/shared/Card"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"

import styles from "./actions.module.scss"

const Actions = ({ slug, id, active, status, hasIntegration, fetchJobs }) => {
  const CLOSED = "closed"
  const { handleStatusUpdate } = useHandleStatusUpdate()
  const [showMenu, setShowMenu] = useState(false)
  const buttonAction = status === CLOSED ? "Activate Job" : "Close Job"

  return (
    <article className={styles.actions}>
      <div className={styles.actions__button}>
        <SecondaryLightButton onClick={() => setShowMenu(!showMenu)}>
          <Icon name="dots" height="22" width="22" />
        </SecondaryLightButton>
      </div>
      {showMenu && (
        <Card className={styles.actions__menu}>
          <div onMouseLeave={() => setShowMenu(false)}>
            <Link
              to={`/jobs/${id}/edit`}
              onClick={() =>
                trackAction("Call to Action Clicked", {
                  location: `Job Listings Table Actions`,
                  click_text: "Edit",
                  click_url: `/jobs/${id}/edit`,
                  modal_name: null,
                })
              }
            >
              <div className={styles.actions__menu__item}>
                <span>Edit</span>
              </div>
            </Link>
            {active && (
              <a
                href={`${process.env.REACT_APP_USER_URL}/sales-jobs/${slug}`}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  trackAction("Call to Action Clicked", {
                    location: `Job Listings Table Actions`,
                    click_text: "View on RepVue",
                    click_url: `${process.env.REACT_APP_USER_URL}/sales-jobs/${slug}`,
                    modal_name: null,
                  })
                }
              >
                <div className={styles.actions__menu__item}>
                  <span>View on RepVue</span>
                  <span className={styles.actions__menu__icon}>
                    <Icon name="external-link" height="16" width="16" />
                  </span>
                </div>
              </a>
            )}
            {!hasIntegration && (
              <div
                className={styles.actions__menu__item}
                onClick={() => handleStatusUpdate(id, status, fetchJobs)}
              >
                <span>{buttonAction}</span>
              </div>
            )}

            {/* <div className={styles.actions__menu__item}>
              <span>Duplicate</span>
              <span className={styles.actions__menu__icon}>
                <Icon name="interrogation" height="16" width="16" />
              </span>
            </div>

            <div className={styles.actions__menu__item}>
              <span className={styles.actions__menu__item__delete}>Delete</span>
              <span className={styles.actions__menu__icon}>
                <Icon name="interrogation" height="16" width="16" />
              </span>
            </div> */}
          </div>
        </Card>
      )}
    </article>
  )
}

export default Actions
