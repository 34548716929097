import SaveSearch from "components/candidates/StartFromJob/SaveSearch"

import Card from "components/shared/Card"
import Icon from "components/Icon"
import styles from "./emptyState.candidate.module.scss"

const EmptyState = ({
  filters,
  searches = [],
  updateSearches,
  searchSelect,
}) => {
  return (
    <Card className={styles.emptyState}>
      <div className={styles.emptyState__icon}>
        <Icon name="audience" height="50" width="50" />
      </div>
      <h6>There aren&apos;t any candidates that match your criteria, yet.</h6>
      <p className={styles.emptyState__description}>
        Never fear! Candidates are always adding new profiles. Save your search
        criteria and we’ll notify you of any new candidates that match.
      </p>

      <div className={styles.emptyState__actions}>
        <SaveSearch
          filters={filters}
          searches={searches}
          updateSearches={updateSearches}
          searchSelect={searchSelect}
        />
      </div>
    </Card>
  )
}

export default EmptyState
