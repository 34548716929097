import { SALES_CYCLE_LABELS } from "constants/candidates"
import SliderFilter from "components/candidates/TagsFilters/Shared/SliderFilter"
import HeaderFilter from "components/candidates/TagsFilters/Shared/HeaderFilter"

const SalesCycleFilter = ({ setFilters, filters, resetFilter }) => {
  return (
    <section>
      <HeaderFilter
        label="Sales Cycle Length"
        action={() => resetFilter(["salesCycleLength"])}
      />

      <SliderFilter
        min={0}
        max={16}
        filters={filters}
        filter="salesCycleLength"
        setFilter={setFilters}
        step={1}
        labelMin="1W"
        labelMax="12M"
        options={SALES_CYCLE_LABELS}
        inputTye="text"
      />
    </section>
  )
}

export default SalesCycleFilter
