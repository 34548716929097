import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { signOut } from "api/auth"
import { Link, useLocation } from "react-router-dom"
import { identifyUser } from "utils/mixpanel"
import Imgix from "react-imgix"
import classNames from "classnames"
import { generateMenuItems } from "constants/sidebar"
import Icon from "components/Icon"
import SubMenuPopOut from "./SubMenuPopOut"
import AccordionMenuItem from "./Accordion"
import ExpandButton from "./ExpandButton"
import logo from "assets/images/repvue.svg"
import bigR from "assets/images/BigR.svg"
import SignOutButton from "./SignOutButton"
import BetaTag from "components/shared/BetaTag"

import styles from "./Sidebar.module.scss"

const Sidebar = ({ collapsed, setCollapsed }) => {
  let location = useLocation()
  const dispatch = useDispatch()
  const { currentEmployer } = useSelector(state => state.auth)
  const { first_name, last_name, company, customer, job_board } =
    currentEmployer
  const { access_type, candidates_active } = customer

  const isExternal = job_board?.applicant_method === "External"

  const freeTierAccess = access_type === "free"

  const [showExpand, setShowExpand] = useState(false)
  const [showSignOut, setShowSignOut] = useState(false)
  const [shownSubMenu, setShownSubMenu] = useState(null)

  const menuItems = generateMenuItems(isExternal, candidates_active)

  return (
    <nav
      className={`${classNames({
        [`${styles.sidebar}`]: true,
        [`${styles.sidebar__collapsed}`]: collapsed,
      })}`}
    >
      <div className={styles.sidebar__wrapper}>
        <div
          className={`${styles.sidebar__brand} mb-2`}
          onMouseLeave={() => setShowExpand(false)}
        >
          {collapsed ? (
            <div className={styles.sidebar__expand}>
              <img
                src={bigR}
                alt="RepVue"
                className={styles.sidebar__bigR}
                onMouseEnter={() => setShowExpand(true)}
              />
              {showExpand && (
                <ExpandButton
                  setShowExpand={setShowExpand}
                  setShownSubMenu={setShownSubMenu}
                  setCollapsed={setCollapsed}
                />
              )}
            </div>
          ) : (
            <img src={logo} alt="RepVue" className={styles.sidebar__logo} />
          )}
          <Icon
            name="home-box"
            height="24"
            width="24"
            className={classNames({
              [`${styles.sidebar__hide}`]: collapsed,
              [`${styles.sidebar__toggle}`]: true,
            })}
            onClick={() => setCollapsed(true)}
          />
        </div>

        <ul
          className={`${styles.sidebar__nav} w-100`}
          onMouseLeave={() => setShownSubMenu(null)}
        >
          {menuItems.map(item => {
            const { title, path, icon, subItems, restricted } = item

            if (restricted && freeTierAccess) return null

            return !subItems ? (
              <li key={title}>
                <Link
                  to={path}
                  className={classNames({
                    [`${styles.sidebar__item__active}`]:
                      location.pathname.match(path),
                    [`${styles.sidebar__item}`]: true,
                    [`${styles.sidebar__item__collapsed}`]: collapsed,
                  })}
                  onMouseEnter={() => setShownSubMenu(null)}
                >
                  <Icon
                    name={icon}
                    height={22}
                    width={22}
                    className={classNames({
                      [`${styles.sidebar__item__icon}`]: !collapsed,
                    })}
                  />
                  {!collapsed && title}

                  {item.beta && <BetaTag />}
                </Link>
              </li>
            ) : collapsed ? (
              <li
                key={title}
                className={classNames({
                  [`${styles.sidebar__item__active}`]:
                    location.pathname.match(path),
                  [`${styles.sidebar__item}`]: true,
                  [`${styles.sidebar__item__collapsed}`]: collapsed,
                })}
                onMouseEnter={() => setShownSubMenu(title)}
              >
                <Icon name={icon} height={22} width={22} />
                {shownSubMenu === title && (
                  <SubMenuPopOut
                    item={item}
                    setShownSubMenu={setShownSubMenu}
                  />
                )}
              </li>
            ) : (
              <AccordionMenuItem
                key={title}
                as="li"
                item={item}
                freeTierAccess={freeTierAccess}
              />
            )
          })}
        </ul>
      </div>
      <div className={styles.sidebar__wrapper}>
        <Link
          to="/settings"
          className={classNames({
            [`${styles.sidebar__item__active}`]:
                    location.pathname.match("settings") !== null,
            [`${styles.sidebar__item}`]: true,
            [`${styles.sidebar__item__collapsed}`]: collapsed,
          })}
        >
          <Icon
            name="settings"
            height={22}
            width={22}
            className={classNames({
              [`${styles.sidebar__item__icon}`]: !collapsed,
            })}
          />
          {!collapsed && "Settings"}
        </Link>
        {/* 
        <Link
          to="#"
          className={classNames({
            [`${styles.sidebar__item}`]: true,
            [`${styles.sidebar__item__collapsed}`]: collapsed,
          })}
        >
          <Icon
            name="bell-vibrating"
            height={22}
            width={22}
            className={classNames({
              [`${styles.sidebar__item__icon}`]: !collapsed,
            })}
          />
          {!collapsed && "Notifications"}
        </Link> */}
        <Link
          to="#"
          className={classNames({
            [`${styles.sidebar__company}`]: true,
            [`${styles.sidebar__company__collapsed}`]: collapsed,
          })}
          onMouseEnter={() => {
            setShowSignOut(true)
          }}
          onMouseLeave={() => {
            setShowSignOut(false)
          }}
        >
          <div
            className={styles.sidebar__signOut}
            onClick={() => {
              identifyUser(currentEmployer)
              dispatch(signOut())
            }}
          >
            <div
              className={classNames({
                [`${styles.sidebar__company__wrapper}`]: true,
                [`${styles.sidebar__company__wrapper__collapsed}`]: collapsed,
              })}
            >
              <Imgix
                src={`${process.env.REACT_APP_IMAGE_URL}/${company.logo}`}
                alt={company.name}
                width={32}
                height={32}
              />
            </div>
            {!collapsed && (
              <>
                <div className={styles.sidebar__company__container}>
                  <strong>{company.name}</strong>
                  <span>{`${first_name} ${last_name}`}</span>
                </div>
                <Icon name="sign-out" height={24} width={24} />
              </>
            )}
            {showSignOut && <SignOutButton collapsed={collapsed} />}
          </div>
        </Link>
      </div>
    </nav>
  )
}

export default Sidebar
