import { useState } from "react"
import { Form } from "react-bootstrap"
import Card from "components/shared/Card"
import Input from "components/shared/Input"
import LabelInput from "components/shared/LabelInput"
import SelectInput from "components/shared/SelectInput"
import PrimaryButton from "components/shared/Buttons/Primary"
import Checkbox from "components/shared/Checkbox"
import LabelTerms from "components/signUp/labelTerms"
import { CustomOption } from "components/signUp/optionsSelect"

import styles from "./form.signUp.module.scss"

const FormSignUp = ({
  activeJobs,
  companies,
  searchCompanies,
  loading,
  onSubmit,
  errors,
  setErrors,
  useOptions,
}) => {
  const [form, setForm] = useState({
    first_name: null,
    last_name: null,
    company: null,
    title: null,
    number_of_active_jobs: null,
    email: null,
    terms_accepted: false,
    primary_use_case: null,
  })

  const validateErrors = () => {
    let hasErrors = false
    if (!form?.terms_accepted) {
      setErrors({ ...errors, terms_accepted: true })
      hasErrors = true
    }

    if (form?.number_of_active_jobs === null) {
      setErrors({ ...errors, number_of_active_jobs: true })
      hasErrors = true
    }

    if (form?.company === null) {
      setErrors({ ...errors, company: true })
      hasErrors = true
    }

    if (form?.primary_use_case === null) {
      setErrors({ ...errors, primary_use_case: true })
      hasErrors = true
    }

    return hasErrors
  }

  const handleSignIn = e => {
    e.preventDefault()
    if (!validateErrors()) {
      onSubmit(form)
    }
  }

  const onChange = e => {
    const { value, name } = e?.target
    setForm({ ...form, [name]: value })
    setErrors({ ...errors, [name]: null })
  }

  const handleSelectChange = (value, name) => {
    setForm({ ...form, [name]: value })
    setErrors({ ...errors, [name]: null })
  }

  const handleCheckboxChange = e => {
    const { checked, name } = e.target
    setForm({ ...form, [name]: checked })
    setErrors({ ...errors, [name]: null })
  }

  const companyError = (() => {
    if (errors?.company)
      return <>Please select from the list of published companies on RepVue</>

    switch (errors?.companyError) {
      case "customer_claimed":
        return (
          <>
            This account has already been claimed. Please reach out to your
            account owner to receive an invite to join. If you need further
            assistance, please contact{" "}
            <a href="mailto:support@repvue.com">support@repvue.com</a>.
          </>
        )
      case "customer_inactive":
        return (
          <>
            This account is inactive. Please reach out to{" "}
            <a href="mailto:support@repvue.com">support@repvue.com</a> to verify
            your access and reactivate your account.
          </>
        )
      default:
        return <>errors?.companyError</>
    }
  })()

  return (
    <Card className={styles.form__card}>
      <Form onSubmit={handleSignIn}>
        <article className={styles.form__row}>
          <div className={styles.form__input}>
            <LabelInput label="First Name" />
            <Input
              type="text"
              name="first_name"
              placeholder="First Name"
              value={form?.first_name || ""}
              onChange={onChange}
              error={errors?.first_name}
              data-cy="sign-up-first-name"
            />
          </div>
          <div className={styles.form__input}>
            <LabelInput label="Last Name" />
            <Input
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={form?.last_name || ""}
              onChange={onChange}
              error={errors?.last_name}
              data-cy="sign-up-last-name"
            />
          </div>
        </article>

        <article className={styles.form__row}>
          <div className={styles.form__input}>
            <LabelInput label="Your Job Title" />
            <Input
              type="text"
              name="title"
              placeholder="Title"
              value={form?.title || ""}
              onChange={onChange}
              error={errors?.title}
              data-cy="sign-up-job-title"
            />
          </div>
        </article>

        <article>
          <div className={styles.form__input}>
            <SelectInput
              label="Select Published Company"
              onChange={data => {
                handleSelectChange(data, "company")
              }}
              onInputChange={searchCompanies}
              name="company"
              isClearable
              placeholder="Select..."
              options={companies}
              error={errors?.company}
              errorMessage={<p>{companyError}</p>}
            />
          </div>

          <div className={styles.form__info}>
            <p>
              Only published company profiles can claim a free account at this
              time. Please <a href="mailto:support@repvue.com">contact us </a>{" "}
              if you are interested in publishing your company profile.
            </p>
          </div>
        </article>

        <article className={styles.form__row}>
          <div className={styles.form__input}>
            <SelectInput
              label="# Of Open Sales Jobs"
              onChange={data => {
                handleSelectChange(data, "number_of_active_jobs")
              }}
              name="number_of_active_jobs"
              placeholder="0-100"
              options={activeJobs}
              error={errors?.number_of_active_jobs}
            />
          </div>
        </article>

        <article className={styles.form__row}>
          <div className={styles.form__input}>
            <SelectInput
              label="How will I primarily use RepVue?"
              onChange={data => {
                handleSelectChange(data, "primary_use_case")
              }}
              name="primary_use_case"
              placeholder="Select..."
              options={useOptions}
              error={errors?.primary_use_case}
              components={{
                Option: CustomOption,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </article>

        <article className={styles.form__row}>
          <div className={styles.form__input}>
            <LabelInput label="Work Email" />
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={form?.email || ""}
              onChange={onChange}
              error={errors?.email}
              data-cy="sign-up-email"
            />
          </div>
        </article>

        <article>
          <Checkbox
            name="terms_accepted"
            onChange={handleCheckboxChange}
            value={form?.terms_accepted || false}
            label={<LabelTerms error={errors?.terms_accepted} />}
            data-cy="sign-up-terms"
          />
        </article>

        <article>
          <PrimaryButton
            className={styles.form__action}
            size="medium"
            type="submit"
            disabled={loading}
            data-cy="sign-up-submit"
          >
            Submit
          </PrimaryButton>
        </article>
      </Form>
    </Card>
  )
}

export default FormSignUp
