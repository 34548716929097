import SaveSearch from "components/candidates/StartFromJob/SaveSearch"
import styles from "./FPOState.candidate.module.scss"

const FPOState = ({ filters, searches = [], updateSearches, searchSelect }) => {
  return (
    <article className={styles.FPOState}>
      <h6>
        You&apos;ve reached the end of the candidate list, but don&apos;t worry!
      </h6>
      <p className={styles.FPOState__description}>
        Candidates are always adding new profiles. Save your search criteria and
        we&apos;ll notify you of any new candidates that match.
      </p>

      <div className={styles.FPOState__actions}>
        <SaveSearch
          searchSelect={searchSelect}
          filters={filters}
          searches={searches}
          updateSearches={updateSearches}
        />
      </div>
    </article>
  )
}

export default FPOState
