import React from "react"
import Icon from "components/Icon"
import styles from "./options.connect.candidates.module.scss"

import { components } from "react-select"
const { Option, SingleValue } = components

const TYPES = {
  "Fully remote": "Fully Remote in US",
}

export const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <div className={styles.control}>
      {" "}
      <Icon name="search" height="18" width="18" /> {children}
    </div>
  </components.Control>
)

export const CustomValue = ({ children, ...props }) => {
  const [selectedOption] = props.getValue()
  return (
    <SingleValue {...props}>
      <article className={styles.customValue}>
        <div className={styles.customValue__label}>{children}</div>

        <div className={styles.customValue__location}>
          {selectedOption?.locations?.length === 0 && (
            <span>
              {TYPES[selectedOption?.locationType] ||
                selectedOption?.locationType}
            </span>
          )}

          {selectedOption?.locations?.map(location => (
            <span key={location?.id}>{location?.name} </span>
          ))}
        </div>
      </article>
    </SingleValue>
  )
}

export const CustomOption = props => (
  <Option {...props}>
    <article>
      <div className={styles.options__label}>{props.data.label}</div>
      <div className={styles.options__location}>
        {props?.data?.locations?.length === 0 && (
          <span>
            {TYPES[props?.data?.locationType] || props?.data?.locationType}
          </span>
        )}

        {props.data.locations?.map(location => (
          <span key={`${location?.id}-option`}>{location?.name}</span>
        ))}
      </div>
    </article>
  </Option>
)
