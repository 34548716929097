import { useEffect, useState } from "react"
import { TECHNOLOGY_INDUSTRIES } from "constants/candidates"
import useCheckBoxFilter from "components/candidates/Hooks/useCheckBoxFilter"
import ListFilter from "components/candidates/TagsFilters/Shared/ListFilter"
import HeaderFilter from "components/candidates/TagsFilters/Shared/HeaderFilter"
import styles from "./industries.module.scss"

const IndustriesFilter = ({ options, setFilters, filters, resetFilter }) => {
  const [industries, setIndustries] = useState([])
  const [technologyIndustries, setTechnologyIndustries] = useState([])
  const { items, updateList } = useCheckBoxFilter({
    options,
    filters,
    setFilters,
    filterKey: "selectedIndustries",
  })

  useEffect(() => {
    const TECHNOLOGY_INDUSTRIES_FILTER = items?.filter(item =>
      TECHNOLOGY_INDUSTRIES.includes(item?.name)
    )
    const INDUSTRIES_FILTER = items?.filter(
      item => !TECHNOLOGY_INDUSTRIES.includes(item?.name)
    )

    setTechnologyIndustries(TECHNOLOGY_INDUSTRIES_FILTER)
    setIndustries(INDUSTRIES_FILTER)
  }, [items])

  return (
    <section className={styles.industriesFilter}>
      <HeaderFilter
        label="Industries"
        action={() => resetFilter(["selectedIndustries"])}
      />

      <article className={styles.industriesFilter__applyed}>
        <ListFilter
          items={items?.filter(item => item?.check)}
          handleCheckboxChange={updateList}
        />
      </article>

      <ListFilter
        items={industries?.filter(item => !item?.check)}
        handleCheckboxChange={updateList}
      />

      <span className={styles.industriesFilter__label}>Technology</span>

      <ListFilter
        items={technologyIndustries?.filter(item => !item?.check)}
        handleCheckboxChange={updateList}
      />
    </section>
  )
}

export default IndustriesFilter
