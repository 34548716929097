import { SALES_CYCLE_LABELS } from "constants/candidates"

export const getSoldToLabels = experience => {
  const names = experience?.selling_personas?.map(item => item.name)
  const result = names?.join(", ")
  return result
}

export const generateCandidateParams = (
  filters = {},
  page = 1,
  per_page = 25
) => {
  const {
    avgDealSize,
    salesCycleLength,
    selectedRoles,
    headquarters,
    selectedIndustries,
    search,
    selectedCompanies,
    yearsExperience,
    yearsClosingExperience,
    selectedPersonas,
    onlyCurrentRoles: only_current_roles,
    activelyLooking,
    sortBy
  } = filters

  const getAverageDealSizeParams = () => {
    if (!avgDealSize) return null

    const { min, max } = avgDealSize
    if (min === 0 && max === 1000000) return null

    return {
      average_deal_size: [min, max],
    }
  }

  const getYearsExperienceParams = () => {
    if (!yearsExperience) return null

    const { min, max } = yearsExperience
    if (min === 0 && max === 11) return null

    return {
      years_selling_experience: [min, max],
    }
  }

  const getYearsClosingExperienceParams = () => {
    if (!yearsClosingExperience) return null

    const { min, max } = yearsClosingExperience
    if (min === 0 && max === 11) return null

    return {
      years_closing_experience: [min, max],
    }
  }

  const getsalesCycleLengthParams = () => {
    if (!salesCycleLength) return null

    const { min, max } = salesCycleLength
    if (min === 0 && max === 16) return null

    return {
      sales_cycle_length: [SALES_CYCLE_LABELS[min], SALES_CYCLE_LABELS[max]],
    }
  }

  const getActivelyLooking = () => {
    if (!activelyLooking) return null

    return {
      "job_preferences_candidate_profiles.search_status": "Actively Looking",
    }
  }

  return {
    ...getAverageDealSizeParams(),
    ...getYearsExperienceParams(),
    ...getYearsClosingExperienceParams(),
    ...getsalesCycleLengthParams(),
    ...getActivelyLooking(),
    role_names: selectedRoles,
    metro_location_ids: headquarters?.map(item => item.id),
    industry_names: selectedIndustries,
    selling_persona_ids: selectedPersonas?.map(item => item.id),
    company_names: selectedCompanies?.map(company => company?.name || company),
    search,
    only_current_roles,
    page,
    per_page,
    sort_key: sortBy || "quality_score",
    sort_direction: "desc"
  }
}

export const conjureWatchedValues = (state, min, max, type, labels) => {
  const valueArray = []

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  })

  Object.keys(state).forEach(valueRange => {
    if (valueRange === "min" && state[`${valueRange}`] > min) {
      valueArray.push(
        type === "currency"
          ? { min: ` ${currencyFormatter.format(state.min)}` }
          : type === "percentage"
          ? { min: `${state.min}%` }
          : { min: `${labels ? labels[state.min] : state.min}` }
      )
    } else if (valueRange === "max" && state[`${valueRange}`] < max) {
      valueArray.push(
        type === "currency"
          ? { max: `${currencyFormatter.format(state.max)}` }
          : type === "percentage"
          ? { max: `${state.max}%` }
          : { max: `${labels ? labels[state.max] : state.max}` }
      )
    }
  })
  return valueArray.length ? valueArray : []
}

const findKeyByValue = value => {
  const entry = Object.entries(SALES_CYCLE_LABELS).find(
    ([_, val]) => val === value
  )
  return entry ? entry[0] : null
}

export const generateFilterOptions = filters => {
  const CYCLE_LENGTH =
    filters?.["candidateSalesExperiencesCandidateProfiles.salesCycleLengthDays"]

  const FILTER_STATE = {
    activelyLooking:
      filters?.["jobPreferencesCandidateProfiles.searchStatus"] || false,
    yearsExperience: {
      min:
        filters?.[
          "jobPreferencesCandidateProfiles.yearsClosingExperience"
        ]?.[0] || 0,
      max:
        filters?.[
          "jobPreferencesCandidateProfiles.yearsClosingExperience"
        ]?.[1] || 11,
    },
    yearsClosingExperience: {
      min:
        filters?.[
          "jobPreferencesCandidateProfiles.yearsSellingExperience"
        ]?.[0] || 0,
      max:
        filters?.[
          "jobPreferencesCandidateProfiles.yearsSellingExperience"
        ]?.[1] || 11,
    },
    avgDealSize: {
      min:
        filters?.[
          "candidateSalesExperiencesCandidateProfiles.averageDealSize"
        ]?.[0] || 0,
      max:
        filters?.[
          "candidateSalesExperiencesCandidateProfiles.averageDealSize"
        ]?.[1] || 1000000,
    },
    basePay: { min: 0, max: 300000 },
    quotaAttainment: { min: 0, max: 100 },
    selectedRoles: filters?.["roles.name"] || [],
    selectedPersonas: filters?.["sellingPersonas.id"] || [],
    selectedIndustries: filters?.["industriesCompanies.name"] || [],
    headquarters: [],
    selectedCompanies:
      filters?.["companiesCandidateSalesExperiences.name"] || [],
    salesCycleLength: {
      min: findKeyByValue(CYCLE_LENGTH?.[0]) || 0,
      max: findKeyByValue(CYCLE_LENGTH?.[1]) || 16,
    },
    onlyCurrentRoles: filters?.onlyCurrentRoles || false,
    search: filters?.search || "",
  }

  return FILTER_STATE
}
