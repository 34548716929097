import { useState } from "react"
import Icon from "components/Icon"
import Card from "components/shared/Card"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"

import styles from "./actions.module.scss"

const Actions = ({ id, deleteSavedSearch }) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <article className={styles.actions}>
      <div className={styles.actions__button}>
        <SecondaryLightButton onClick={() => setShowMenu(!showMenu)}>
          <Icon name="dots" height="22" width="22" />
        </SecondaryLightButton>
      </div>
      {showMenu && (
        <Card className={styles.actions__menu}>
          <div onMouseLeave={() => setShowMenu(false)}>
            <div
              className={styles.actions__menu__item}
              onClick={() => {
                deleteSavedSearch(id)
              }}
            >
              <span>Delete</span>
            </div>
          </div>
        </Card>
      )}
    </article>
  )
}

export default Actions
