import { YEARS_LABELS } from "constants/candidates"
import SliderFilter from "components/candidates/TagsFilters/Shared/SliderFilter"
import HeaderFilter from "components/candidates/TagsFilters/Shared/HeaderFilter"

const YearsExperienceFilter = ({ setFilters, filters, resetFilter }) => {
  return (
    <section>
      <HeaderFilter
        label="Years of Experience"
        action={() => resetFilter(["yearsExperience"])}
      />

      <SliderFilter
        min={0}
        max={11}
        filters={filters}
        filter="yearsExperience"
        setFilter={setFilters}
        step={1}
        labelMin="<1Y"
        labelMax="10Y+"
        options={YEARS_LABELS}
        inputTye="text"
      />
    </section>
  )
}

export default YearsExperienceFilter
