import { useEffect, useState, useCallback } from "react"
import { Button, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { getSearches, deleteSearch } from "api/candidate/searches"
import toast from "react-hot-toast"
import Header from "components/Header"
import Content from "components/Content"
import Icon from "components/Icon"
import SavedSearchTable from "components/candidates/SavedSearchTable"
import Pagination from "components/talentData/Table/Pagination"
import ErrorToast from "components/shared/Notifications/ErrorToast"
import styles from "./savedSearch.module.scss"

const SavedSearchSourceCandidatesRoute = () => {
  const [savedSearches, setSavedSearches] = useState([])
  const [loading, setLoading] = useState(true)
  const [meta, setMeta] = useState({})

  const fetchConnections = useCallback((pageIndex = 1) => {
    const params = { page: pageIndex }

    getSearches(params)
      .then(res => {
        const { data } = res
        setSavedSearches(data?.searches)
        setMeta(res?.meta)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleChange = useCallback(
    pageIndex => {
      fetchConnections(pageIndex)
    },
    [fetchConnections]
  )

  const deleteSavedSearch = id => {
    deleteSearch(id)
      .then(() => fetchConnections())
      .catch(() =>
        toast.custom(t => <ErrorToast t={t} headline="Something went wrong" />)
      )
  }

  useEffect(() => {
    fetchConnections()
  }, [])

  return (
    <div className="ads scroll-container">
      <Header title="Source Candidates" />

      <Content>
        <div className={styles.back}>
          <Link to="/source-candidates">
            <Icon name="back-arrow" height={12} width={14} />
            Back to Find Candidates
          </Link>
        </div>

        <div className={styles.titleWrapper}>
          <h3>Manage Saved Job Searches</h3>
          <p>
            Manage your saved job search to optimize your candidate search
            experience.
          </p>
        </div>
        <div className={styles.wrapper}>
          <Link to="/source-candidates">
            <Button className={styles.topButton}>
              Create New <Icon name="plus" height="15" width="15" />
            </Button>
          </Link>

          {!loading ? (
            <SavedSearchTable
              savedSearches={savedSearches}
              deleteSavedSearch={deleteSavedSearch}
            />
          ) : (
            <div className="jobListingsLoader">
              <Spinner animation="border" />
            </div>
          )}

          {!loading && meta?.page < meta?.total_pages && (
            <div className="rv-tfoot">
              <Pagination
                handleChange={handleChange}
                meta={meta}
                sortBy={null}
                perPage={50}
              />
            </div>
          )}
        </div>
      </Content>
    </div>
  )
}

export default SavedSearchSourceCandidatesRoute
