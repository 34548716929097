import useCandidateTagsFilters from "components/candidates/Hooks/useTagsFilter"
import PillDropdown from "components/shared/PillDropdown"
import IndustriesFilter from "./Filters/Industries"
import RoleTypeFilter from "./Filters/RoleType"
import LocationFilter from "./Filters/Location"
import BuyerPersonaFilter from "./Filters/BuyerPersona"
import AvgDealSizeFilter from "./Filters/AvgDealSize"
import YearsExperienceFilter from "./Filters/YearsExperience"
import SalesCycleFilter from "./Filters/SalesCycle"
import MoreFilter from "./Filters/MoreFilters"
import styles from "./filters.candidate.module.scss"

const CandidateTagsFilters = ({ filters, setFilters }) => {
  const {
    formOptions,
    locations,
    companies,
    TAGS,
    isActive,
    getCount,
    fetchCompanies,
    fetchLocations,
    resetFilter,
  } = useCandidateTagsFilters(filters, setFilters)

  const FILTERS_CONFIG = [
    {
      key: "selectedRoles",
      label: filters?.selectedRoles?.[0] || "Role Type",
      component: RoleTypeFilter,
      options: formOptions?.roles,
      active: isActive(filters?.selectedRoles),
      count: getCount(filters?.selectedRoles),
      resetFilter: resetFilter,
      placement: "bottom-start",
    },
    {
      key: "headquarters",
      label: filters?.headquarters?.[0]?.name || "Location",
      component: LocationFilter,
      options: locations,
      handleSearch: fetchLocations,
      active: isActive(filters?.headquarters),
      count: getCount(filters?.headquarters),
      resetFilter: resetFilter,
    },
    {
      key: "selectedIndustries",
      label: filters?.selectedIndustries?.[0] || "Industry",
      component: IndustriesFilter,
      options: formOptions?.industries,
      active: isActive(filters?.selectedIndustries),
      count: getCount(filters?.selectedIndustries),
      resetFilter: resetFilter,
    },
    {
      key: "yearsExperience",
      label:
        TAGS.experience.length > 0
          ? `${
              TAGS.experience[0]?.min || TAGS.experience[1]?.min || "<1 Years"
            } - ${
              TAGS.experience[0]?.max || TAGS.experience[1]?.max || "10+ Years"
            }`
          : "Experience",
      component: YearsExperienceFilter,
      active: isActive(TAGS.experience),
      resetFilter: resetFilter,
    },
    {
      key: "avgDealSize",
      label:
        TAGS.avgDealSize.length > 0
          ? `${TAGS.avgDealSize[0]?.min || "$0"} - ${
              TAGS.avgDealSize[1]?.max || "$1M"
            }`
          : "Avg. Deal Size",
      component: AvgDealSizeFilter,
      active: isActive(TAGS.avgDealSize),
      resetFilter: resetFilter,
    },
    {
      key: "selectedPersonas",
      label: filters?.selectedPersonas?.[0]?.name || "Buyer Persona",
      component: BuyerPersonaFilter,
      options: formOptions?.selling_personas,
      active: isActive(filters?.selectedPersonas),
      count: getCount(filters?.selectedPersonas),
      resetFilter: resetFilter,
    },
    {
      key: "salesCycleLength",
      label:
        TAGS.salesCycle.length > 0
          ? `${TAGS.salesCycle[0]?.min || "1W"} - ${
              TAGS.salesCycle[1]?.max || "12M"
            }`
          : "Sales Cycle Length",
      component: SalesCycleFilter,
      active: isActive(TAGS.salesCycle),
      resetFilter: resetFilter,
    },
  ]

  return (
    <section className={styles.filters}>
      {FILTERS_CONFIG.map(
        ({
          key,
          label,
          component: Component,
          options,
          handleSearch,
          active,
          count,
          resetFilter,
          placement,
        }) => (
          <PillDropdown
            key={key}
            active={active}
            label={label}
            placement={placement}
            count={count}
            body={
              <Component
                options={options}
                setFilters={setFilters}
                filters={filters}
                handleSearch={handleSearch}
                resetFilter={resetFilter}
              />
            }
          />
        )
      )}

      <PillDropdown
        icon="plus"
        widthIcon={14}
        active={
          isActive(filters?.search) ||
          isActive(filters?.selectedCompanies) ||
          isActive(TAGS.closingExperience)
        }
        label="More Filters"
        body={
          <MoreFilter
            setFilters={setFilters}
            filters={filters}
            handleSearch={fetchLocations}
            companies={companies}
            handleSearchCompanies={fetchCompanies}
            resetFilter={resetFilter}
          />
        }
      />
    </section>
  )
}

export default CandidateTagsFilters
