/**
 * debounce
 * @param {Function} func function
 * @param {Number} wait time to wait
 * @param {Boolean} [immediate]
 * @returns
 */
export const debounce = (func, wait, immediate) => {
  let timeout
  return function () {
    const context = this,
      args = arguments
    clearTimeout(timeout)
    if (immediate && !timeout) func.apply(context, args)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
  }
}

export const toCamelCase = obj => {
  const convertToCamel = str =>
    str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const camelKey = convertToCamel(key)

    if (value && typeof value === "object" && !Array.isArray(value)) {
      acc[camelKey] = toCamelCase(value)
    } else {
      acc[camelKey] = value
    }

    return acc
  }, {})
}
