import useCheckBoxFilter from "components/candidates/Hooks/useCheckBoxFilter"
import SwitchFilter from "components/shared/Filters/Switch"
import ListFilter from "components/candidates/TagsFilters/Shared/ListFilter"
import HeaderFilter from "components/candidates/TagsFilters/Shared/HeaderFilter"
import styles from "./roleType.module.scss"

const RoleTypeFilter = ({ options, setFilters, filters, resetFilter }) => {
  const { items, updateList } = useCheckBoxFilter({
    options,
    filters,
    setFilters,
    filterKey: "selectedRoles",
  })

  return (
    <section className={styles.roleType}>
      <HeaderFilter
        label="Role Type"
        action={() => resetFilter(["selectedRoles", "onlyCurrentRoles"])}
      />

      <article className={styles.roleType__applyed}>
        <ListFilter
          items={items?.filter(item => item?.check)}
          handleCheckboxChange={updateList}
        />
        <SwitchFilter
          setFilters={setFilters}
          filters={filters}
          label="Show Only Current Role"
          name="onlyCurrentRoles"
        />
      </article>

      <ListFilter
        items={items?.filter(item => !item?.check)}
        handleCheckboxChange={updateList}
      />
    </section>
  )
}

export default RoleTypeFilter
