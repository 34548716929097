import { useState, useEffect } from "react"
import { validateUrl } from "utils/validations"
import { updateEmployer } from "api/auth"
import { useDispatch } from "react-redux"
import { trackAction } from "utils/mixpanel"

import { Modal, Spinner } from "react-bootstrap"
import toast from "react-hot-toast"
import Input from "components/shared/Input"
import PrimaryButton from "components/shared/Buttons/Primary"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import ErrorToast from "components/shared/Notifications/ErrorToast"

import styles from "./schedulingLinkModal.module.scss"

const SchedulingLinkModal = ({
  show,
  modalClose,
  schedulingLink,
  showStartWithAJobModal,
}) => {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    url: null,
  })

  const closeModal = () => {
    if (showStartWithAJobModal) showStartWithAJobModal(true)
    modalClose(false)
  }

  const updateEmployerRequest = payload => {
    dispatch(updateEmployer(payload))
      .then(res => {
        closeModal()
      })
      .catch(err => {
        console.log("err", err)
        closeModal()
        toast.custom(t => <ErrorToast t={t} headline="Something went wrong" />)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submit = useEmail => {
    if (!validateUrl(form?.url) && !useEmail)
      return setErrors({ ...errors, url: "Please enter a valid URL" })

    setLoading(true)
    const payload = {
      employer: {
        scheduling_link: useEmail ? null : form?.url,
      },
    }
    trackAction("Call to Action Clicked", {
      location: "Source Candidates",
      click_text: "Submit",
      click_url: null,
      modal_name: "Scheduling Link",
    })
    updateEmployerRequest(payload)
  }

  const onChange = e => {
    const { value, name } = e?.target
    setForm({ ...form, [name]: value })
    setErrors({ ...errors, [name]: null })
  }

  useEffect(() => {
    setForm({ url: schedulingLink })
  }, [schedulingLink])

  return (
    <Modal
      show={show}
      className={styles.schedulingLinkModal__container}
      centered
      size="lg"
    >
      <Modal.Body className={styles.schedulingLinkModal__body}>
        <section className={styles.schedulingLinkModal__header}>
          <h5>Ready To Source The Best Candidates?</h5>
          <p>
            In order to connect with candidates we require a scheduling link to
            coordinate meetings.
          </p>
        </section>

        <section className={styles.schedulingLinkModal__shareLink}>
          <div className={styles.schedulingLinkModal__shareLink__}></div>

          <div className={styles.schedulingLinkModal__form}>
            <p className={styles.schedulingLinkModal__form__label}>
              Provide your scheduling link
            </p>
            <Input
              type="text"
              name="url"
              placeholder="https://"
              value={form?.url || ""}
              onChange={onChange}
              error={errors?.url}
            />
          </div>
        </section>

        <section className={styles.schedulingLinkModal__actions}>
          <PrimaryButton disabled={loading} onClick={() => submit(false)}>
            {loading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              "Submit and Continue"
            )}
          </PrimaryButton>

          <SecondaryLightButton
            variant="link"
            disabled={loading}
            className={styles.schedulingLinkModal__actions__email}
            onClick={() => {
              trackAction("Call to Action Clicked", {
                location: "Source Candidates",
                click_text: "I'll add this later",
                click_url: null,
                modal_name: "Scheduling Link",
              })
              closeModal()
            }}
          >
            I&apos;ll add this later
          </SecondaryLightButton>
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default SchedulingLinkModal
