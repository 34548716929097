import { useState } from "react"
import { Spinner } from "react-bootstrap"
import { saveSearch, updateSearch } from "api/candidate/searches"
import { toCamelCase } from "utils/common"
import { generateCandidateParams } from "utils/candidateProfile"
import toast from "react-hot-toast"
import ErrorToast from "components/shared/Notifications/ErrorToast"
import SuccessToast from "components/shared/Notifications/SuccessToast"
import PrimaryButton from "components/shared/Buttons/Primary"
import styles from "./saveSearch.candidate.module.scss"

const SaveSearch = ({
  filters,
  searches = [],
  updateSearches,
  jobSelectedId,
  searchSelect,
}) => {
  const [loading, setLoading] = useState(false)

  const saveNewSearch = payload => {
    saveSearch(payload)
      .then(res => {
        const filter = {
          ...res,
          filter: toCamelCase(res?.filter),
        }

        const searchesMap = [filter, ...searches]
        updateSearches([...searchesMap])
      })
      .catch(err => {
        toast.custom(t => <ErrorToast t={t} headline="Something went wrong" />)
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateCurrentSearch = payload => {
    updateSearch(searchSelect?.uuid, payload)
      .then(res => {
        const filter = {
          ...res,
          filter: toCamelCase(res?.filter),
        }
        const updatedSearchesMap = searches.map(search =>
          search.uuid === searchSelect?.uuid ? { ...search, ...filter } : search
        )

        updateSearches(updatedSearchesMap)

        toast.custom(t => (
          <SuccessToast t={t} headline="Search updated successfully." />
        ))
      })
      .catch(err => {
        toast.custom(t => <ErrorToast t={t} headline="Something went wrong" />)
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submit = () => {
    setLoading(true)

    const FILTERS = generateCandidateParams(filters)
    const CONFIGURATION = toCamelCase(filters)

    const payload = {
      search: {
        job_id: searchSelect?.uuid ? null : jobSelectedId,
        filter: { ...FILTERS, configuration: { ...CONFIGURATION } },
      },
    }

    searchSelect?.uuid ? updateCurrentSearch(payload) : saveNewSearch(payload)
  }

  return (
    <article className={styles.saveSearch}>
      <PrimaryButton onClick={submit} disabled={loading}>
        {loading ? (
          <Spinner size="sm" animation="border" />
        ) : searchSelect?.uuid ? (
          "Update Search"
        ) : (
          "Save Search"
        )}
      </PrimaryButton>
    </article>
  )
}

export default SaveSearch
