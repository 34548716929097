export function cleanTrim(string, maxLength = 145, rough = false) {
  if (string) {
    const roughTrim = string.substr(0, maxLength)

    if (rough) {
      return string.length < maxLength
        ? string
        : roughTrim.substr(0, maxLength) + "..."
    }

    const trimmedDescription = roughTrim.substr(
      0,
      Math.min(roughTrim.length, roughTrim.lastIndexOf(" "))
    )
    return string.length < maxLength ? string : trimmedDescription + "..."
  }
  return ""
}

/**
 * formatBytes - this function formats file sizes
 * @param {Number} bytes
 * @returns
 */
export function formatBytes(bytes) {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  let size = bytes
  let unitIndex = 0

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024
    unitIndex++
  }

  size = Math.round(size * 100) / 100

  return `${size} ${units[unitIndex]}`
}

export const htmlStripper = html => {
  return html.replace(/(\\r\\n|\\n|\\r\|)|(<iframe.*?\/iframe>)/gm, "")
}

export const formattedErrors = errors => {
  return Object.fromEntries(
    Object.entries(errors).map(([key, value]) => [key, value.join(", ")])
  )
}

/**
 * toSentenceCase - this function formats strings into sentence case
 * @param {String} inputString
 * @returns
 */
export function toSentenceCase(inputString) {
  if (typeof inputString !== "string" || inputString.length === 0) {
    return undefined
  }

  return inputString.charAt(0).toUpperCase() + inputString.slice(1)
}

export const getCurrencyFormat = (num, currency = "USD") => {
  const formatNumber = Number.parseFloat(num).toFixed(0)

  const options = {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }
  return new Intl.NumberFormat(undefined, options).format(formatNumber)
}

export const getDate = date => {
  const dateObject = new Date(date)
  const options = { year: "numeric", month: "numeric", day: "numeric" }
  return dateObject.toLocaleDateString("en-US", options)
}

export const createCommaSeparatedString = data => {
  return data
    ?.map(item => (typeof item === "object" && item?.name ? item?.name : item))
    .join(", ")
}
