import { Link } from "react-router-dom"

import Card from "components/shared/Card"
import EmptySavedSearches from "./Empty"
import SearchesList from "./SearchesList"
import styles from "./savedSearches.candidate.module.scss"

const SavedSearches = ({
  savedSearches,
  setFilters,
  searchSelect,
  setSearchSelect,
}) => {
  return (
    <article className={styles.savedSearches}>
      <div className={styles.savedSearches__header}>
        <span className={styles.savedSearches__header__title}>
          Saved Searches
        </span>

        <Link to="/source-candidates/saved-searches">Manage</Link>
      </div>

      <Card className={styles.savedSearches__card}>
        {savedSearches?.length > 0 ? (
          <SearchesList
            searches={savedSearches}
            setFilters={setFilters}
            searchSelect={searchSelect}
            setSearchSelect={setSearchSelect}
          />
        ) : (
          <EmptySavedSearches />
        )}

        <SearchesList />
      </Card>
    </article>
  )
}

export default SavedSearches
