import apiCall from "utils/api"

export const getSearches = params => {
  const options = { params }
  return apiCall("get", `candidate/searches`, options)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const saveSearch = params => {
  const options = { data: params }

  return apiCall("post", `candidate/searches`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Candidate Save Search", error)
      throw error
    })
}

export const deleteSearch = id => {
  return apiCall("delete", `candidate/searches/${id}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Candidate Delete Search", error)
      throw error
    })
}

export const updateSearch = (uuid, params) => {
  const options = { data: params }
  return apiCall("put", `candidate/searches/${uuid}`, options)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error("Candidate Delete Search", error)
      throw error
    })
}
