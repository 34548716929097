import { useNavigate } from "react-router-dom"
import { updateJob } from "api/jobs"

const useHandleStatusUpdate = () => {
  const navigate = useNavigate()
  const CLOSED = "closed"

  const handleStatusUpdate = (id, status, action) => {
    const newStatus = status === CLOSED ? "Active" : "Closed"
    const payload = { job: { status: newStatus } }

    updateJob(id, payload).then(() => {
      action ? action() : navigate(`/jobs?status=${newStatus}`)
    })
  }

  return { handleStatusUpdate }
}

export default useHandleStatusUpdate
