import { useState } from "react"
import { saveSearch } from "api/candidate/searches"
import { Modal, Spinner } from "react-bootstrap"
import { generateCandidateParams } from "utils/candidateProfile"
import { trackAction } from "utils/mixpanel"
import toast from "react-hot-toast"

import SearchFromJob from "components/candidates/StartFromJob/Search"
import PrimaryButton from "components/shared/Buttons/Primary"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import ErrorToast from "components/shared/Notifications/ErrorToast"

import styles from "./startWithAJobModal.module.scss"

const StartWithAJobModal = ({
  show,
  modalClose,
  jobs = [],
  fetchSavedSearches,
  getJobInfo,
  setJobSelected,
}) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState()

  const closeModal = () => {
    setForm(null)
    modalClose(false)
  }

  const submitSearch = payload => {
    setLoading(true)
    trackAction("Start from a Job Applied", { job_posting: form?.label })

    saveSearch(payload)
      .then(() => {
        fetchSavedSearches()
        closeModal()
        setJobSelected(form)
      })
      .catch(err => {
        console.log("err", err)
        closeModal()
        toast.custom(t => <ErrorToast t={t} headline="Something went wrong" />)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const submit = async () => {
    if (!form?.value) return setError("Please select a job")

    setLoading(true)

    const updatedFilters = await getJobInfo(form?.value)

    const FILTERS = generateCandidateParams(updatedFilters)

    const payload = {
      search: {
        job_id: form?.value,
        filter: {
          ...FILTERS,
          configuration: { ...updatedFilters },
        },
      },
    }

    submitSearch(payload)
  }

  const onChange = value => {
    setForm(value)
    setError(null)
  }

  return (
    <Modal
      show={show}
      className={styles.schedulingLinkModal__container}
      centered
      size="lg"
    >
      <Modal.Body className={styles.schedulingLinkModal__body}>
        <section className={styles.schedulingLinkModal__header}>
          <h5>Great! Let&apos;s start with a job.</h5>
          <p>
            With a saved search you’ll be notified of any new candidates that
            match your criteria.
          </p>
        </section>

        <section className={styles.schedulingLinkModal__shareLink}>
          <div className={styles.schedulingLinkModal__shareLink__}></div>

          <div className={styles.schedulingLinkModal__form}>
            <p className={styles.schedulingLinkModal__form__label}>
              Select a job to view matching candidates
            </p>
            <SearchFromJob
              jobs={jobs}
              modal
              onChange={onChange}
              value={form}
              errorMessage={error}
            />
          </div>
        </section>

        <section className={styles.schedulingLinkModal__actions}>
          <PrimaryButton disabled={loading} onClick={() => submit(false)}>
            {loading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              "Save and View Candidates"
            )}
          </PrimaryButton>

          <SecondaryLightButton
            variant="link"
            disabled={loading}
            className={styles.schedulingLinkModal__actions__email}
            onClick={closeModal}
          >
            I&apos;ll add this later
          </SecondaryLightButton>
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default StartWithAJobModal
