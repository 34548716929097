import { useState, useRef } from "react"
import cn from "classnames"
import OutsideClickDetector from "hooks/outsideClickDetector"
import Icon from "components/Icon"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import styles from "./pillDropdown.module.scss"

const PillDropdown = ({
  placement = "bottom",
  label,
  body,
  active,
  icon = "down-arrow",
  widthIcon = 12,
  count,
}) => {
  const popoverRef = useRef()
  const [show, setShow] = useState(false)

  const openFilter = () => {
    setShow(state => !state)
  }

  const handleClickOutside = event => {
    setShow(false)
  }

  return (
    <article className={styles.pillDropdown}>
      <OutsideClickDetector
        onClick={handleClickOutside}
        exceptionRef={popoverRef}
      >
        <OverlayTrigger
          show={show}
          placement={placement}
          transition
          overlay={
            <Popover id="popover-contained" style={{ minWidth: "20rem" }}>
              <div
                className={cn(styles.pillDropdown__content, {
                  [styles.pillDropdown__content__start]:
                    placement === "bottom-start",
                })}
                ref={popoverRef}
              >
                {body}
              </div>
            </Popover>
          }
        >
          <div
            className={cn(styles.pillDropdown__pill, {
              [styles.pillDropdown__pill__active]: show || active,
            })}
            onClick={openFilter}
          >
            <div className={styles.pillDropdown__pill__containerLabel}>
              <span className={styles.pillDropdown__pill__label}>{label}</span>{" "}
              {count && (
                <span className={styles.pillDropdown__pill__count}>
                  +{count}
                </span>
              )}
            </div>
            <Icon name={icon} height={widthIcon} width={widthIcon} />{" "}
          </div>
        </OverlayTrigger>
      </OutsideClickDetector>
    </article>
  )
}

export default PillDropdown
