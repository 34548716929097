import { useMemo, useState, useEffect } from "react"
import Form from "react-bootstrap/Form"
import debounce from "lodash/debounce"
import Icon from "components/Icon"
import styles from "./searchFilter.module.scss"

const SearchFilter = ({ handleSearch, placeholder = "Search", search }) => {
  const [value, setValue] = useState(search || "")

  const debounceHandleSearch = useMemo(
    () => debounce(handleSearch, 300),
    [handleSearch]
  )

  const changeValue = value => {
    setValue(value)
    debounceHandleSearch(value)
  }

  useEffect(() => {
    setValue(search)
  }, [search])

  return (
    <div className={styles.search__inputWrapper}>
      <Icon
        name="search"
        height="16"
        width="16"
        className={styles.search__inputWrapper__icon}
      />
      <Form.Control
        className={styles.input}
        type="search"
        placeholder={placeholder}
        name="search"
        value={value || ""}
        onChange={e => changeValue(e.target.value)}
      />
    </div>
  )
}

export default SearchFilter
