import { useState, useEffect } from "react"
import { getFilterOptions } from "api/candidate/profiles"
import { getMetroLocationsSansCities } from "api/metro_locations"
import { searchCompaniesProfile } from "api/companies"
import { conjureWatchedValues } from "utils/candidateProfile"
import {
  YEARS_LABELS,
  SALES_CYCLE_LABELS,
  FILTER_STATE_INIT,
} from "constants/candidates"

const useCandidateTagsFilters = (filters, setFilters) => {
  const [formOptions, setFormOptions] = useState({})
  const [locations, setLocations] = useState([])
  const [companies, setCompanies] = useState([])

  const TAGS = {
    experience: conjureWatchedValues(
      filters.yearsExperience,
      0,
      11,
      "text",
      YEARS_LABELS
    ),
    avgDealSize: conjureWatchedValues(
      filters.avgDealSize,
      0,
      1000000,
      "currency"
    ),
    salesCycle: conjureWatchedValues(
      filters.salesCycleLength,
      0,
      16,
      "text",
      SALES_CYCLE_LABELS
    ),
    closingExperience: conjureWatchedValues(
      filters.yearsClosingExperience,
      0,
      11,
      "text",
      YEARS_LABELS
    ),
  }

  const isActive = tags => tags?.length > 0
  const getCount = tags => (tags?.length > 1 ? tags?.length - 1 : false)

  const fetchCompanies = search => {
    searchCompaniesProfile(search)
      .then(response => setCompanies(response.companies))
      .catch(error => console.error(error))
  }

  const fetchLocations = search => {
    getMetroLocationsSansCities(search)
      .then(response => setLocations(response.rows))
      .catch(error => console.error(error))
  }

  const setDefaults = () => {
    getFilterOptions().then(setFormOptions).catch(console.error)
    fetchLocations()
  }

  const resetFilter = keysToReset => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters }

      keysToReset.forEach(key => {
        if (key in FILTER_STATE_INIT) {
          updatedFilters[key] = FILTER_STATE_INIT[key]
        }
      })

      return updatedFilters
    })
  }

  useEffect(() => {
    if (filters?.selectedCompanies?.length === 0) setCompanies([])
  }, [filters])

  useEffect(() => {
    setDefaults()
  }, [])

  return {
    formOptions,
    locations,
    companies,
    TAGS,
    isActive,
    getCount,
    fetchCompanies,
    fetchLocations,
    resetFilter,
  }
}

export default useCandidateTagsFilters
