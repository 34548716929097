import styles from "./empty.savedSearches.candidate.module.scss"

const EmptySavedSearches = () => {
  return (
    <article className={styles.emptySavedSearches}>
      <h6 className={styles.emptySavedSearches__title}>No Saved Search, yet</h6>
      <p className={styles.emptySavedSearches__description}>
        Try saving a search to be notified when new candidates match your
        criteria!
      </p>
    </article>
  )
}

export default EmptySavedSearches
