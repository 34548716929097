import Sort from "components/shared/Sort"

import styles from "./headerInformation.candidate.module.scss"

const HeaderInformation = ({ count, filters, setFilters }) => {
  const handleSelection = params => {
    setFilters({ ...filters, sortBy: params?.sort_key })
  }

  const options = [
    {
      label: "Profile Quality",
      params: { sort_key: "quality_score", sort_direction: "desc" },
    },
    {
      label: "New",
      params: { sort_key: "published_at", sort_direction: "desc" },
    },
  ]

  return (
    <article className={styles.headerInformation}>
      <div className={styles.headerInformation__tagContainer}>
        <span className={styles.headerInformation__title}>Candidates </span>
        <span className={styles.headerInformation__tag}>{count}</span>
        <Sort
          options={options}
          handleSelection={handleSelection}
          defaultOption={options[0]?.label}
        />
      </div>
    </article>
  )
}

export default HeaderInformation
