import cn from "classnames"
import SearchFilter from "components/candidates/TagsFilters/Shared/SearchFilter"
import useCheckBoxFilter from "components/candidates/Hooks/useCheckBoxFilter"
import ListFilter from "components/candidates/TagsFilters/Shared/ListFilter"
import styles from "./location.module.scss"

const TargetCompaniesFilter = ({
  options,
  setFilters,
  filters,
  handleSearch,
}) => {
  const { items, updateList, itemsSelected } = useCheckBoxFilter({
    options,
    filters,
    setFilters,
    filterKey: "selectedCompanies",
  })

  return (
    <section className={styles.targedCompanies}>
      <SearchFilter
        handleSearch={handleSearch}
        placeholder="Search Target Companies"
      />

      <article
        className={cn({
          [styles.targedCompanies__applyed]: itemsSelected.length > 0,
        })}
      >
        <ListFilter items={itemsSelected} handleCheckboxChange={updateList} />
      </article>

      <article className={styles.targedCompanies__list}>
        <ListFilter
          items={items?.filter(item => !item?.check)}
          handleCheckboxChange={updateList}
        />
      </article>
    </section>
  )
}

export default TargetCompaniesFilter
