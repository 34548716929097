import ItemSearch from "./ItemSearch"
import styles from "./list.savedSearches.candidate.module.scss"

const SearchesList = ({
  searches,
  setFilters,
  searchSelect,
  setSearchSelect,
}) => {
  return (
    <article className={styles.searchesList}>
      {searches?.map(search => (
        <ItemSearch
          key={search.uuid}
          search={search}
          setFilters={setFilters}
          searchSelect={searchSelect}
          setSearchSelect={setSearchSelect}
        />
      ))}
    </article>
  )
}

export default SearchesList
