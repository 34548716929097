import { useState, useEffect } from "react"

const useCheckBoxFilter = ({
  options,
  filters,
  setFilters,
  filterKey,
  queryWithId,
}) => {
  const [items, setItems] = useState([])
  const [itemsSelected, setItemsSelected] = useState([])

  const updateFilter = updatedItems => {
    const selectedItems = updatedItems
      ?.filter(item => item.check)
      .map(item =>
        queryWithId ? { name: item.value, id: item.id } : item.value
      )

    setFilters(state => ({
      ...state,
      [filterKey]: selectedItems,
    }))
  }

  const updateList = e => {
    const { checked, name } = e.target

    const updatedItems = items.map(item => ({
      ...item,
      check: item.name === name ? checked : item.check,
    }))

    const itemToAddOrRemove = items.find(item => item.name === name)

    const selection = checked
      ? [...itemsSelected, { ...itemToAddOrRemove, check: true }]
      : itemsSelected.filter(item => item.name !== name)

    setItems(updatedItems)
    setItemsSelected(selection)
    updateFilter(selection)
  }

  useEffect(() => {
    const optionsMap = options?.map(option => ({
      name: option?.name || option,
      label: option?.name || option,
      value: option?.name || option,
      id: option?.id,
      check: queryWithId
        ? filters?.[filterKey]?.some(item => item.id === option?.id)
        : filters?.[filterKey]?.includes(option?.name || option),
    }))

    const optionsSelected = filters?.[filterKey]?.map(option => ({
      name: option?.name || option,
      label: option?.name || option,
      value: option?.name || option,
      id: option?.id,
      check: true,
    }))

    setItemsSelected(optionsSelected)

    setItems(optionsMap)
  }, [options, filters, filterKey])

  return {
    items,
    updateList,
    itemsSelected,
  }
}

export default useCheckBoxFilter
