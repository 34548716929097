import SearchFilter from "components/candidates/TagsFilters/Shared/SearchFilter"
import useCheckBoxFilter from "components/candidates/Hooks/useCheckBoxFilter"
import ListFilter from "components/candidates/TagsFilters/Shared/ListFilter"
import HeaderFilter from "components/candidates/TagsFilters/Shared/HeaderFilter"
import styles from "./location.module.scss"

const LocationFilter = ({
  options,
  setFilters,
  filters,
  handleSearch,
  resetFilter,
}) => {
  const { items, updateList, itemsSelected } = useCheckBoxFilter({
    options,
    filters,
    setFilters,
    filterKey: "headquarters",
    value: "id",
    queryWithId: true,
  })

  return (
    <section className={styles.locations}>
      <HeaderFilter
        label="Location"
        action={() => resetFilter(["headquarters"])}
      />

      <SearchFilter
        handleSearch={handleSearch}
        placeholder="Search locations"
      />

      <article className={styles.locations__applyed}>
        <ListFilter items={itemsSelected} handleCheckboxChange={updateList} />
      </article>

      <article className={styles.locations__list}>
        <ListFilter
          items={items?.filter(item => !item?.check)}
          handleCheckboxChange={updateList}
        />
      </article>
    </section>
  )
}

export default LocationFilter
