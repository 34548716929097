import SliderSelect from "components/shared/Filters/SliderSelect"

import styles from "./sliderFilter.module.scss"

const SliderFilter = ({
  setFilter,
  filters,
  min,
  max,
  filter,
  step,
  labelMin,
  labelMax,
  type,
  options,
  inputTye,
}) => {
  return (
    <SliderSelect
      min={min}
      max={max}
      filters={filters}
      filter={filter}
      setFilter={setFilter}
      step={step}
      labelMin={labelMin}
      labelMax={labelMax}
      type={type}
      options={options}
      inputTye={inputTye}
      containerClassName={styles.sliderFilter__containerFilter}
      inputContainerClassName={styles.sliderFilter__inputContainer}
    />
  )
}

export default SliderFilter
