import cn from "classnames"
import Checkbox from "components/shared/Checkbox"
import styles from "./listFilter.module.scss"

const ListFilter = ({ items, handleCheckboxChange }) => {
  return (
    <article>
      {items?.map(item => (
        <div
          key={item.value}
          className={cn(styles?.listFilter, {
            [styles?.listFilter__check]: item.check,
          })}
        >
          <Checkbox
            name={item.name}
            onChange={handleCheckboxChange}
            value={item.check}
            label={item?.label}
          />
        </div>
      ))}
    </article>
  )
}

export default ListFilter
