import { useEffect, useState } from "react"
import { getEmployersFormOptions, employersRegister } from "api/employers"
import { getSignUpSearch } from "api/companies"
import { formattedErrors } from "utils/string"
import FormSignUp from "components/signUp/form"

import styles from "./register.signUp.module.scss"

const RegisterForm = ({ setEmailSend }) => {
  const [companies, setCompanies] = useState([])
  const [activeJobs, setActiveJobs] = useState([])
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const USE_OPTIONS = [
    {
      value: "Source and hire talent",
      label: "Source and Hire Talent",
      description: "Find and recruit top-performing sales professionals easily",
    },
    {
      value: "Manage company brand",
      label: "Manage company brand",
      description:
        "Engage with your company's reviews and Q&A to strengthen your reputation",
    },
    {
      value: "Other",
      label: "Other",
      description: "Use RepVue for other employer-related needs",
    },
  ]

  const submitEmployers = data => {
    setLoading(true)
    const payload = {
      employer: {
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        title: data?.title,
        terms_accepted: data?.terms_accepted,
        primary_use_case: data?.primary_use_case?.value,
        customer_attributes: {
          company_id: data?.company?.value,
          number_of_active_jobs: data?.number_of_active_jobs?.value,
        },
      },
    }

    employersRegister(payload)
      .then(() => {
        setEmailSend(data?.email)
      })
      .catch(err => {
        const { data } = err

        if (data?.message)
          return setErrors({ companyError: data?.message, company: true })

        setErrors(formattedErrors(data))
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  const getCompanies = search => {
    getSignUpSearch(search || "").then(response => {
      const { companies } = response
      const companiesMap = companies?.map(company => ({
        label: company?.name,
        value: company?.id,
      }))

      setCompanies(companiesMap)
    })
  }

  const getOptions = () => {
    getEmployersFormOptions().then(response => {
      const { number_of_active_jobs } = response
      const jobs = number_of_active_jobs?.map(job => ({
        label: job,
        value: job,
      }))
      setActiveJobs(jobs)
    })
  }

  useEffect(() => {
    getCompanies()
    getOptions()
  }, [])

  return (
    <section className={styles.registerForm}>
      <h3 className={styles.registerForm__title}>Claim your Free Profile!</h3>
      <p className={styles.registerForm__description}>
        Claim your free profile to showcase your employer brand and hire top
        sales talent from the RepVue community!
      </p>

      <FormSignUp
        activeJobs={activeJobs}
        companies={companies}
        searchCompanies={getCompanies}
        loading={loading}
        onSubmit={submitEmployers}
        errors={errors}
        setErrors={setErrors}
        useOptions={USE_OPTIONS}
      />
    </section>
  )
}

export default RegisterForm
