import Table from "./Table"
import EmptyTable from "components/shared/EmptyTable"
const SavedSearchTable = ({ savedSearches, deleteSavedSearch }) => {
  return (
    <article>
      {savedSearches?.length > 0 ? (
        <Table data={savedSearches} deleteSavedSearch={deleteSavedSearch} />
      ) : (
        <EmptyTable
          title={`You don't have any Saved Job Searches yet`}
          description="Saved Job Searches will be listed here."
          buttonText="Source Candidates"
          buttonUrl="/source-candidates"
        />
      )}
    </article>
  )
}

export default SavedSearchTable
