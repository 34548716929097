import React from "react"
import styles from "./options.module.scss"

import { components } from "react-select"
const { Option } = components

export const CustomOption = props => {
  return (
    <Option {...props}>
      <article className={styles.options}>
        <div className={styles.options__label}>{props.data.label}</div>
        <div className={styles.options__description}>
          <span> {props?.data?.description}</span>
        </div>
      </article>
    </Option>
  )
}
