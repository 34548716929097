import cn from "classnames"
import { useState, useRef } from "react"

import OutsideClickDetector from "hooks/outsideClickDetector"
import { OverlayTrigger, Popover } from "react-bootstrap"
import Icon from "components/Icon"

import styles from "./Sort.module.scss"

const Sort = ({ handleSelection, options, defaultOption }) => {
  const popoverRef = useRef()
  const [sort, setSort] = useState(defaultOption || options[0]?.label)
  const [showOptions, setShowOptions] = useState(false)

  const handleClickOutside = () => {
    setShowOptions(false)
  }

  const openFilter = () => {
    setShowOptions(state => !state)
  }
  const placement = "bottom"

  const maxLabelLength = options.reduce(
    (max, current) => Math.max(max, current.label.length),
    0
  )

  const Option = ({ label, params }) => {
    const handleOptionClick = () => {
      setSort(label)
      handleSelection(params)
    }
    const activeOption = label === sort

    return (
      <span
        onClick={handleOptionClick}
        className={cn(styles.dropdown__content__option, {
          [styles.dropdown__content__option__active]: activeOption,
        })}
      >
        {label}
        {activeOption && <Icon name="check" height={13} width={13} />}
      </span>
    )
  }

  return (
    <article className={styles.sort}>
      <OutsideClickDetector
        onClick={handleClickOutside}
        exceptionRef={popoverRef}
      >
        <div
          className={cn(styles.dropdown, {
            [styles.dropdown__active]: showOptions,
          })}
          onClick={openFilter}
        >
          Sort By: <span>{sort}</span>
          <OverlayTrigger
            show={showOptions}
            placement={placement}
            transition
            overlay={
              <Popover id="popover-contained">
                <div
                  className={styles.dropdown__content}
                  ref={popoverRef}
                  style={{ right: `-${maxLabelLength * 0.35}px` }}
                >
                  {options.map(option => (
                    <Option
                      label={option.label}
                      params={option.params}
                      key={option.label}
                    />
                  ))}
                </div>
              </Popover>
            }
          >
            <div className="d-flex align-items-center">
              <Icon
                name="chevron"
                height={14}
                width={14}
                className={cn(styles.chevron, {
                  [styles.chevron__active]: showOptions,
                })}
              />
            </div>
          </OverlayTrigger>
        </div>
      </OutsideClickDetector>
    </article>
  )
}

export default Sort
