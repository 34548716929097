import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import { YEARS_LABELS } from "constants/candidates"
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table"
import { createCommaSeparatedString } from "utils/string"
import {
  DefaultFilterForColumn,
  SelectColumnFilter,
} from "components/shared/Table/Filter"
import TableComponent from "components/shared/Table"
import Card from "components/shared/Card"
import Actions from "components/candidates/SavedSearchTable/Components/Actions"
import SearchCell from "components/candidates/SavedSearchTable/Components/Search"

import styles from "./Table.module.scss"

const Table = ({ data, deleteSavedSearch }) => {
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        Header: "Saved Job Search",
        hideHeader: true,
        columns: [
          {
            Header: "Search",
            accessor: "name",

            Cell: ({
              cell: {
                row: {
                  original: { name, results_count, results_delta },
                },
              },
            }) =>
              (
                <SearchCell
                  name={name}
                  count={results_count}
                  delta={results_delta}
                />
              ) || "--",
            width: "flex",
            colSpan: 1,
            className: styles.searchCell,
          },

          {
            Header: "Created",
            accessor: "created_at",
            Cell: ({ cell: { value } }) => {
              const formattedDate = dayjs(new Date(Date.parse(value))).format(
                "MMM DD, YYYY"
              )
              if (formattedDate === "Invalid Date") {
                return "--"
              }
              return <span className={styles.nameCell}>{formattedDate}</span>
            },
            width: "flex",
            colSpan: 1,
            className: styles.created,
          },

          {
            Header: "Role",
            accessor: "filter.configuration.selected_roles",

            Cell: ({ cell: { value } }) => {
              return (
                <span className={styles.nameCell}>
                  {createCommaSeparatedString(value) || "--"}
                </span>
              )
            },
            width: "flex",
            colSpan: 1,
            className: styles.tableCell,
          },

          {
            Header: "Locations",
            accessor: "filter.configuration.headquarters",

            Cell: ({ cell: { value } }) => {
              return (
                <span className={styles.nameCell}>
                  {createCommaSeparatedString(value) || "--"}
                </span>
              )
            },
            width: "flex",
            className: styles.tableCell,
          },

          {
            Header: "Industries",
            accessor: "filter.configuration.selected_industries",

            colSpan: 2,
            Cell: ({ cell: { value } }) => {
              return (
                <span className={styles.nameCell}>
                  {createCommaSeparatedString(value) || "--"}
                </span>
              )
            },
            width: "flex",
            className: styles.activeCell,
          },

          {
            Header: "Experience",
            accessor: "filter.configuration.years_experience",

            Cell: ({ cell: { value } }) => {
              return (
                <span className={styles.nameCell}>
                  {`${YEARS_LABELS[value?.min]} - ${
                    YEARS_LABELS[value?.max]
                  }` || "--"}
                </span>
              )
            },
            width: "flex",
            colSpan: 1,
            className: styles.tableCell,
          },

          {
            Header: " ",
            accessor: "",

            Cell: ({
              cell: {
                row: {
                  original: { uuid },
                },
              },
            }) =>
              <Actions id={uuid} deleteSavedSearch={deleteSavedSearch} /> ||
              "--",

            width: "flex",
            colSpan: 1,
            className: styles.actions,
            hasAction: true,
          },
        ],
      },
    ],
    [data]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn: {
          Filter: SelectColumnFilter,
          DefaultFilter: DefaultFilterForColumn,
        },
        initialState: {
          sortBy: [
            {
              id: "created_at",
              desc: true,
            },
          ],
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    )

  const getFilters = data => {
    navigate(`/source-candidates/searches/${data?.uuid}`)
  }

  return (
    <Card className={styles.table}>
      {data.length > 0 && (
        <TableComponent
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          className={styles.table}
          onRowClick={getFilters}
        />
      )}
    </Card>
  )
}

export default Table
