import useCheckBoxFilter from "components/candidates/Hooks/useCheckBoxFilter"
import ListFilter from "components/candidates/TagsFilters/Shared/ListFilter"
import HeaderFilter from "components/candidates/TagsFilters/Shared/HeaderFilter"
import styles from "./buyerPersona.module.scss"

const BuyerPersonaFilter = ({ options, setFilters, filters, resetFilter }) => {
  const { items, updateList, itemsSelected } = useCheckBoxFilter({
    options,
    filters,
    setFilters,
    filterKey: "selectedPersonas",
    queryWithId: true,
    value: "id",
  })

  return (
    <section className={styles.roleType}>
      <HeaderFilter
        label="Buyer Persona"
        action={() => resetFilter(["selectedPersonas"])}
      />

      <article className={styles.roleType__applyed}>
        <ListFilter items={itemsSelected} handleCheckboxChange={updateList} />
      </article>

      <ListFilter
        items={items?.filter(item => !item?.check)}
        handleCheckboxChange={updateList}
      />
    </section>
  )
}

export default BuyerPersonaFilter
