import { useState, useEffect } from "react"
import { FILTER_STATE_INIT } from "constants/candidates"
import SaveSearch from "./SaveSearch"
import SearchFromJob from "./Search"
import ClearAll from "./ClearAll"
import styles from "./startFromJob.candidate.module.scss"

const StartFromJob = ({
  jobs,
  setFilters,
  filters,
  updateSearches,
  searches,
  getJobInfo,
  jobSelected,
  searchSelect,
  setSearchSelect,
  cleanUrl,
}) => {
  const [form, setForm] = useState(null)

  const updateFilter = value => {
    setForm(value)
    getJobInfo(value?.value)
  }

  const clearFilters = () => {
    setForm(null)
    setFilters(FILTER_STATE_INIT)
    setSearchSelect({})
    cleanUrl()
  }

  useEffect(() => {
    if (jobSelected?.value) updateFilter(jobSelected)

    if (searchSelect?.uuid)
      setForm({ value: searchSelect?.uuid, label: searchSelect?.name })
  }, [jobSelected, searchSelect])

  return (
    <article className={styles.startFromJob}>
      <div className={styles.startFromJob__searchContainer}>
        <SearchFromJob jobs={jobs} onChange={updateFilter} value={form} />
      </div>

      <SaveSearch
        filters={filters}
        updateSearches={updateSearches}
        searches={searches}
        jobSelectedId={form?.value}
        searchSelect={searchSelect}
      />
      <ClearAll onClick={clearFilters} />
    </article>
  )
}

export default StartFromJob
